/* Footer.css */
.site-footer {
    /* background-color: #f2f2f2; */

    text-align: center;
    padding: 20px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    background-color: #0B1F32;
}

.footer-links {
    list-style: none;
    padding: 0;
}

.footer-links li {
    display: inline;
    margin: 0 10px;
}

.footer-links a {
    color: #005A6D;
    text-decoration: none;
}