.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* min-height: 90vh; */
    max-height: 90vh;
    margin-top: 20vh;
    border: none;
    outline: none;
    background-color: #0B1F32;
    overflow: auto;
    /*Ensures content is scrollable if it exceeds the viewport*/
}

.text-area1,
.text-area2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Source Sans 3', sans-serif;
    font-weight: 300;
    font-size: 24px;
    letter-spacing: 0.175em;
    color: #6690A2;
    flex: 1 1 auto;
    /*Allows these areas to grow and shrink but with auto basis
    */
}

.text-area1 {
    margin-bottom: 5vh;
}

.text-area2 {
    margin-bottom: 9vh;
}

.text-area1 p,
.text-area2 p {
    justify-content: center;
    margin-bottom: 5vh;
    margin-left: 5vw;
    margin-right: 5vw;
}

.text-area2 a {
    color: inherit;
    text-decoration: none;
    font-weight: 900;
}

.image-area img {
    width: 100%;
    max-width: 900px;
    max-height: 140px;
    height: auto;
    flex: 0 0 auto;
    /* Prevents image from shrinking */
}


@media only screen and (min-width: 200px) {

    .text-area1,
    .text-area2 {
        font-size: 16px;
    }
}

/* @media only screen and (min-width: 520px) {
    .text-area1, .text-area2 {
        font-size: 12px;
    }
}

@media only screen and (min-width: 610px) {
    .text-area1, .text-area2 {
        font-size: 14px;
    }
}

@media only screen and (min-width: 700px) {
    .text-area1, .text-area2 {
        font-size: 16px;
    }
} */

@media only screen and (min-width: 800px) {

    .text-area1,
    .text-area2 {
        font-size: 17.5px;
    }
}

@media only screen and (min-width: 900px) {

    .text-area1,
    .text-area2 {
        font-size: 20px;
    }
}

@media only screen and (min-width: 1050px) {

    .text-area1,
    .text-area2 {
        font-size: 23px;
    }
}

@media only screen and (min-width: 1250px) {

    .text-area1,
    .text-area2 {
        font-size: 26px;
    }

    /* .audio-input {
        height: 1em;
        max-height: 6em;
    } */
}