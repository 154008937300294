/* General Page Styles, assuming these might already be in place but reiterated here for consistency */
body,
html {
    margin: 0;
    padding: 0;
    font-family: 'Source Sans 3', sans-serif;
    background-color: #0B1F32;
    /* Keeping the main font family */
}

body {
    padding-bottom: 100px;
}

/* Header remains unchanged, assuming it's a site-wide component */
.header {
    position: relative;
    display: flex;
    align-items: baseline;
    justify-content: start;
    height: 10vh;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
}

/* Terms of Service Page Specific Styles */
.terms-container {
    /* padding: 2rem; */
    padding: 20px;
    max-width: 800px;
    /* Max-width for optimal reading */
    margin: 0 auto;
    /* Centering the content */
    color: #6690A2;
    /* Keeping the main page's color scheme */
}

.terms-heading {
    font-family: 'Urbanist', sans-serif;
    /* Maintaining font family consistency */
    color: #005A6D;
    /* Header color from the main page */
    font-weight: bold;
    margin-bottom: 1rem;
}

.terms-section {
    margin-bottom: 2rem;
}

.terms-section {
    font-family: 'Source Sans 3', sans-serif;
    /* Main text font */
    font-size: 1rem;
    /* Default size, can adjust based on preference */
    line-height: 1.6;
    /* Improved readability for long texts */
    text-align: justify;
    /* Optional: Justify text for cleaner edges */
    font-style: normal;
    /* Default styling, adjust as needed */
}

.terms-section p {
    margin-bottom: 20px;
    /* Space between paragraphs */
}

.terms-section em {
    font-style: italic;
}

.terms-section strong {
    font-weight: 900;
}

.terms-section .underline {
    text-decoration: underline;
}

.terms-section span.underline {
    text-decoration: underline;
    display: inline;
    /* Explicitly ensure inline display */
}

.terms-section {
    counter-reset: section;
    /* Resets the section counter */
}

.terms-section>ol {
    list-style: none;
    /* Removes default list styling */
    padding-left: 0;
    /* Adjusts padding to control indentation */
}

.terms-section ul {
    list-style-type: disc;
    /* Default bullet style, change as needed (e.g., circle, square) */
    padding-left: 20px;
    /* Adjust indentation of bullet points */
}

/* Section Numbering */
.terms-section>ol>li {
    counter-increment: section;
    /* Increments the section counter */
    margin-bottom: 10px;
    /* Adds spacing between sections */
}

.terms-section>ol>li::before {
    content: counter(section) ". ";
    /* Displays section number */
    padding-right: 5px;
    /* Spacing between the number and text */
}

/* Subsection Numbering */
.terms-section>ol>li>ol {
    counter-reset: subsection;
    /* Resets the subsection counter for each section */
    list-style: none;
    /* Removes default list styling */
    padding-left: 20px;
    /* Controls indentation of subsections */
}

.terms-section>ol>li>ol>li::before {
    counter-increment: subsection;
    /* Increments the subsection counter */
    content: counter(section) "." counter(subsection) " ";
    /* Displays subsection numbering as '1.1', '1.2',... */
    padding-right: 5px;
    /* Spacing between the number and text */
}

/* Sub-subsection Lettering */
.terms-section>ol>li>ol>li>ol {
    list-style-type: lower-alpha;
    /* Changes list style to lowercase letters for the third level */
    padding-left: 40px;
    /* Controls indentation of sub-subsections */
}

/* Target the direct li children of the main ol for spacing between main sections */
.terms-section>ol>li {
    margin-bottom: 20px;
    /* Space between main sections */
}

/* Apply bottom margin to each list item within subsections for spacing */
.terms-section>ol>li>ol>li {
    margin-bottom: 15px;
    /* Space between subsection list items */
}

/* Add bottom margin to li items that contain ol lists */
.terms-section ol li>ol {
    margin-top: 10px;
    /* Adjust the value as needed */
}

/* Specifically target the strong and span tags within the li for subsection titles without forcing them onto a new line */
.terms-section>ol>li>strong,
.terms-section>ol>li>ol>li>span {
    /* margin-bottom: 10px; */
    /* Space after section and subsection titles */
    display: inline-block;
    /* Use inline-block if needed to apply margin without breaking line */
}

/* Adjusts spacing for list items in deeper nested lists, ensuring proper visual separation */
.terms-section ol li ol li {
    margin-bottom: 10px;
}

.terms-section a {
    /* color: #007bff; */
    /* Link color */
    font-weight: bold;
    text-decoration: bold;
    /* Removes underline */
}

.terms-section a:hover {
    text-decoration: underline;
    /* Adds underline on hover */
    /* color: #0056b3; */
    /* Darker shade of blue on hover */
}

/* If you need specific styles for the FAQ section */
.faq-section .faq-item p {
    padding-left: 20px;
    /* Indent answers for readability */
}

/* Ensure the FAQ items have some spacing between them for clarity */
.faq-section .faq-item {
    margin-bottom: 20px;
    /* Adjust spacing between FAQ items */
}

.terms-subheading {
    font-family: 'Urbanist', sans-serif;
    /* Consistency with the main page */
    font-weight: 600;
    color: #005A6D;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.terms-text {
    font-family: 'Source Sans 3', sans-serif;
    /* Main text font */
    font-size: 1rem;
    /* Default size, can adjust based on preference */
    line-height: 1.6;
    /* Improved readability for long texts */
    text-align: justify;
    /* Optional: Justify text for cleaner edges */
}

.arbitration-clause {
    border: 2px solid #005A6D;
    padding: 20px;
    /* Space inside the box */
    margin: 20px 0;
    /* Add some space around the box */
    text-align: justify;
    text-transform: uppercase;
    /* Make the text uppercase */
}

/* Responsive adjustments */
@media only screen and (min-width: 200px) {
    .terms-heading {
        font-size: 24px;
        /* Larger header for larger screens */
    }

    .terms-text {
        font-size: 14px;
    }
}

@media only screen and (min-width: 700px) {
    .terms-heading {
        font-size: 28px;
        /* Larger header for larger screens */
    }

    .terms-text {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1000px) {
    .terms-heading {
        font-size: 32px;
        /* Larger header for larger screens */
    }

    .terms-text {
        font-size: 18px;
        /* Slightly larger text for larger screens */
    }
}