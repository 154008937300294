.login-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 70%; */
  margin-left: 25%;
  margin-right: 2%;
  margin-top: 30vh;
  background-color: #0B1F32;
}

.login-label {
  color: #18798E;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 300;
  font-size: 28px;
}

.login-input {
  width: 100%;
  border: none;
  color: #E9FFFE;
  font-size: 28px;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 300;
  background-color: #0B1F32;
  caret-color: #18798E;
  outline: none;
}

@media only screen and (min-width: 200px) {
    .login-label, .login-input {
        font-size: 16px;
    }
    .login-input {
        margin-left: 0.2em;
    }
}

@media only screen and (min-width: 300px) {
    .login-label, .login-input {
        font-size: 17.5px;
    }    
}

@media only screen and (min-width: 400px) {
    .login-label, .login-input {
        font-size: 23px;
    }
}

@media only screen and (min-width: 1000px) {
    .login-label, .login-input {
        font-size: 28px;
    }
}

@media only screen and (max-width: 700px) {
    .login-form {
        margin-left: 1rem;
    }
}