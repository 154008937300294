.header {
    position: relative;
    display: flex;
    align-items: baseline;
    justify-content: start;
    height: 10vh;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    overflow: auto;
}

.text-part1 {
    font-family: 'Source Sans 3', sans-serif;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0.175em;
    color: #6690A2;
    vertical-align: middle;
}

.text-part2 {
    display: inline-block;
    font-family: 'Urbanist', sans-serif;
    font-style: mixed;
    font-weight: 900;
    font-size: 23.16px;
    letter-spacing: 0.175em;
    color: #005A6D;
    position: relative;
    vertical-align: middle;
    bottom: 0.03vh;
}

@media only screen and (min-width: 200px) {
    .text-part1 {
        font-size: 16px;
    }

    .text-part2 {
        font-size: 15.44px;
    }
}

@media only screen and (min-width: 300px) {
    .text-part1 {
        font-size: 20px;
    }

    .text-part2 {
        font-size: 19.3px;
    }
}

@media only screen and (min-width: 700px) {
    .text-part1 {
        font-size: 22px;
    }

    .text-part2 {
        font-size: 21.23px;
    }
}

@media only screen and (min-width: 1000px) {
    .text-part1 {
        font-size: 24px;
    }

    .text-part2 {
        font-size: 23.16px;
    }
}