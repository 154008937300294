.audio-player {
  /* display: flex;
  flex-direction: column; */
  height: 90vh; /* total available height */
  border: none;
  outline: none;
}


.audio-input,
.ai-input {
  /* flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 10%; */
  /* max-height: 30vh;  */
  max-height: 5.8em; 
  width: 90%;
  margin-left: 1rem;
  overflow-y: hidden;
  border: none;
  outline: none;
  resize: none;
  font-size: 24px;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 300;
  color: #18798E;
  background-color: #0B1F32;
  caret-color: #18798E;
}

.audio-input {
  margin-top: 5vh;
}

.ai-input {
  margin-top: 1em;
}

/* .music-visualization {
  max-height:10px !important;
} */

@media only screen and (min-width: 200px) {
    .audio-input, .ai-input {
        font-size: 16px;
    }
}

@media only screen and (min-width: 300px) {
    .audio-input, .ai-input {
        font-size: 17.5px;
    }
}

@media only screen and (min-width: 400px) {
    .audio-input, .ai-input {
        font-size: 23px;
    }
}

@media only screen and (min-width: 1000px) {
    .audio-input, .ai-input {
        font-size: 28px;
    }
    /* .audio-input {   
        height: 1em;         
        max-height: 6em;
    } */
}
